import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import TOC from "../page/toc";
import Depliant from "../page/depliant";

const Headings = ({ title, icon, paragraphs, depliantOn }) => {
  const logoStyle = {
    objectFit: "cover",
    width: "100%",
    height: "auto",
  };
  const hStyle = {
    fontSize: "1.6rem",
    fontWeight: "bold",
  };
  return (
    <div className="row mt-3 ">
      <div className="order-first p-1 container">
        <div className="row">
          {" "}
          <div className="col-4 ">
            <Image
              style={logoStyle}
              className="rounded"
              fluid={icon.childImageSharp.fluid}
              alt={title}
            />
          </div>
          <div className="col-8 align-self-center p-1">
            <h1 style={hStyle}>{title}</h1>
          </div>
        </div>
      </div>
      <aside className=" col-md-4  order-md-2 p-1">
        {depliantOn ? <Depliant /> : <></>}
      </aside>
    </div>
  );
};

export default Headings;

Headings.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  paragraphs: PropTypes.arrayOf(PropTypes.object),
  depliantOn: PropTypes.bool,
};

Headings.defaultProps = {
  title: "",
  icon: {},
  paragraphs: [],
  depliantOn: true,
};
