import React from 'react';
import Markdown from 'markdown-to-jsx';
import Bullet from '../page/bullet';

const Paragraph = ({
  name, content, anchor, color, images, children,
}) => {
  const paragraphStyle = {
    backgroundColor: color,
    borderColor: color,
    boxShadow: '1px 3px 2px #888888',
  };
  if (images === undefined) {
    images = [];
  }
  return (
    <div className="row">
      <div>
        <a className="anchor" id={anchor} />
        <button className="link-button" />
        <div className="d-flex justify-content-start  mb-3 align-items-center ">
          <Bullet color={color} />
          <div className="pageCard card-text pl-1 pt-0 ">
            <h2 className="card-text pageCard">
              {' '}
              {name}
              {' '}
            </h2>
          </div>
        </div>
      </div>
      <div className="mb-3 p-1 mt-1 w-100" style={paragraphStyle}>
        {content != undefined ? <Markdown>{content}</Markdown> : <></>}
        {children}
      </div>
    </div>
  );
};

export default Paragraph;
