import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Paragraph from '../components/paragraphs/paragraphWithoutImages';
import Headings from '../components/paragraphs/headings_page';

export default function Template({ data, pageContext }) {
  const { site, page } = data;

  const paragraphs = [
    {
      key: 'details',
      name: page.details.nom,
      value: page.details,
      color: page.details.couleur.valeur,
    },
    {
      key: 'acces',
      name: page.acces.nom,
      value: page.contact,
      color: page.acces.couleur.valeur,
    },
  ];

  return (
    <Layout
      title={`${site.siteMetadata.title} - ${page.nom}`}
      buildTime={pageContext.buildTime}
    >
      <SEO meta={page.meta} />
      <div>
        <Headings title={page.nom} icon={page.icon} paragraphs={paragraphs} />

        <Paragraph
          key="k_details"
          name={page.details.nom}
          content={page.details.contenu}
          anchor="details"
          color={page.details.couleur.valeur}
        />
        <Paragraph
          key="k_acces"
          name={page.acces.nom}
          content={page.acces.contenu}
          anchor="acces"
          color={page.acces.couleur.valeur}
        />
        <div id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1312.1673898178599!2d2.40008871163941!3d48.8708942024143!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf51bc9ad9cc93397!2sAtelier+Saint+Fargeau!5e0!3m2!1sfr!2sus!4v1537122085859"
            width="100%"
            height="400px"
            frameBorder="0"
            style={{ border: '0' }}
            allowFullScreen
          />
        </div>
      </div>
    </Layout>
  );
}

const getDate = (time) => {
  const _arr = time.split('-'); // 2020-09-28
  const year = _arr[0];
  const month = _arr[1] - 1;
  const day = _arr[2];
  return new Date(year, month, day, 12);
};

const Calendrier = ({ calendrier }) => {
  const periods = calendrier.map((item) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const strDeb = getDate(item.debut);
    const strFin = getDate(item.fin);
    const deb = strDeb.toLocaleDateString('fr-FR', options);
    const fin = strFin.toLocaleDateString('fr-FR', options);
    // console.log(deb)
    const liStyle = {
      margin: '0.5rem',
      listStyle: 'none',
    };
    return (
      <li style={liStyle} key={item.id}>
        <strong>
          {item.nom}
          {' '}
          :
          {' '}
        </strong>
        Début :
        {' '}
        {deb}
        {' '}
        - Fin :
        {' '}
        {fin}
      </li>
    );
  });
  return <ul className="p-1">{periods}</ul>;
};

export const pageQuery = graphql`
  query informationsPratiquesQuery {
    site: site {
      siteMetadata {
        title
        description
        author
      }
    }
    page: strapiInformationsGenerales {
      nom
      icon {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      acces {
        contenu
        nom
        couleur {
          valeur
        }
      }
      contact {
        contenu
        nom
        couleur {
          valeur
        }
      }
      details {
        contenu
        couleur {
          valeur
        }
        nom
      }
      image_presentation {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      meta {
        description
        title
        url
        image {
          publicURL
          childImageSharp {
            fixed(width: 200) {
              width
              height
              src
            }
          }
        }
      }
    }
    calendrier: strapiCalendrierSessions {
      calendrier {
        debut
        fin
        id
        nom
      }
    }
  }
`;
