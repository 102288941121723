import React from "react"

const Bullet = ({ color }) => {
  const itemStyle = {
    backgroundColor: color,
    borderWidth: "1rem",
    padding: "0px",
    margin: "1px",
  }
  return <div className="btn" style={itemStyle} />
}
export default Bullet
